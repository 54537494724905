import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/builds/frontend/chameleon-docs/src/components/Layout/DefaultLayout.js";
import ColorGrid from 'components/ColorGrid';
import ColorSwatch from 'components/ColorSwatch';
import Columns from 'components/Columns';
import { Flex } from 'CHAMELEON_REACT_HUB';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <h2 {...{
      "id": "naming",
      "style": {
        "position": "relative"
      }
    }}>{`Naming`}</h2>
    <Columns cols={{
      md: '1fr',
      lg: "2fr 1fr"
    }} mdxType="Columns">
      <p>{`Chameleon's color system is designed to be unambigous and accessible. It comes
in different themes per brand. Each brand is compromised of of brand specific
colors (e.g., primary, secondary) and shared platform colors (e.g., greys,
supportive blue).`}</p>
    </Columns>
    <h2 {...{
      "id": "brand-colors",
      "style": {
        "position": "relative"
      }
    }}>{`Brand colors`}</h2>
    <Columns mdxType="Columns">
  <ColorSwatch colorName="Primary" tokenName="colorPrimaryBase" textTokenName="colorTextPrimaryBase" mdxType="ColorSwatch" />
  <ColorSwatch colorName="Secondary" tokenName="colorSecondaryBase" textTokenName="colorTextSecondaryBase" mdxType="ColorSwatch" />
  <ColorSwatch colorName="Tertiary" tokenName="colorTertiaryBase" textTokenName="colorTextTertiaryBase" mdxType="ColorSwatch" />
    </Columns>
    <h2 {...{
      "id": "marketing-color",
      "style": {
        "position": "relative"
      }
    }}>{`Marketing color`}</h2>
    <Columns mdxType="Columns">
  <ColorSwatch colorName="Marketing" tokenName="colorMarketingBase" textTokenName="colorTextMarketingBase" mdxType="ColorSwatch" />
    </Columns>
    <h2 {...{
      "id": "platform-colors",
      "style": {
        "position": "relative"
      }
    }}>{`Platform colors`}</h2>
    <h3 {...{
      "id": "feedback",
      "style": {
        "position": "relative"
      }
    }}>{`Feedback`}</h3>
    <Columns mdxType="Columns">
  <ColorSwatch colorName="Supportive Blue" tokenName="colorBlueBase" textTokenName="colorTextBlueBase" usage="Link actions" mdxType="ColorSwatch" />
  <ColorSwatch colorName="Supportive Green" tokenName="colorGreenBase" textTokenName="colorTextGreenBase" usage="Positive feedback messages" mdxType="ColorSwatch" />
  <ColorSwatch colorName="Supportive Red" tokenName="colorRedBase" textTokenName="colorTextRedBase" usage="Negative feedback messages" mdxType="ColorSwatch" />
    </Columns>
    <h3 {...{
      "id": "ui-colors",
      "style": {
        "position": "relative"
      }
    }}>{`UI colors`}</h3>
    <Columns mdxType="Columns">
  <ColorSwatch colorName="Grey Base" tokenName="colorGreyBase" textTokenName="colorTextGreyBase" usage="Borders" mdxType="ColorSwatch" />
    </Columns>
    <h2 {...{
      "id": "extended-palettes",
      "style": {
        "position": "relative"
      }
    }}>{`Extended palettes`}</h2>
    <Columns cols={{
      md: '1fr',
      lg: "2fr 1fr"
    }} mdxType="Columns">
      <p>{`Every color, whether it's a brand or platform color, has an extended palette
which consists of shades and tints. They come in handy for interactions and
illustrations for our products.`}</p>
    </Columns>
    <h3 {...{
      "id": "corresponding-accessible-text-colors",
      "style": {
        "position": "relative"
      }
    }}>{`Corresponding accessible text colors`}</h3>
    <Columns cols={{
      md: '1fr',
      lg: "2fr 1fr"
    }} mdxType="Columns">
      <p>{`Color lightness-to-darkness is consistent across color hues, so that every color
10–50 is accessible (4.5:1) on black, and every color 60–100 is accessible
(4.5:1) on white.`}</p>
    </Columns>
    <h3 {...{
      "id": "primary-colors",
      "style": {
        "position": "relative"
      }
    }}>{`Primary colors`}</h3>
    <ColorGrid name="primary" mdxType="ColorGrid" />
    <h3 {...{
      "id": "secondary-colors",
      "style": {
        "position": "relative"
      }
    }}>{`Secondary colors`}</h3>
    <ColorGrid name="secondary" mdxType="ColorGrid" />
    <h3 {...{
      "id": "tertiary-colors",
      "style": {
        "position": "relative"
      }
    }}>{`Tertiary colors`}</h3>
    <ColorGrid name="tertiary" mdxType="ColorGrid" />
    <h3 {...{
      "id": "marketing-colors",
      "style": {
        "position": "relative"
      }
    }}>{`Marketing colors`}</h3>
    <ColorGrid name="marketing" mdxType="ColorGrid" />
    <h3 {...{
      "id": "grey-colors",
      "style": {
        "position": "relative"
      }
    }}>{`Grey colors`}</h3>
    <p>{`The grey color palette is used to indicate status of an object, text,
background, …`}</p>
    <ColorGrid name="grey" mdxType="ColorGrid" />
    <h3 {...{
      "id": "supportive-blues",
      "style": {
        "position": "relative"
      }
    }}>{`Supportive Blues`}</h3>
    <p>{`The supportive blue is used to initiate an action or a neutral outcome.`}</p>
    <ColorGrid name="blue" mdxType="ColorGrid" />
    <h3 {...{
      "id": "supportive-greens",
      "style": {
        "position": "relative"
      }
    }}>{`Supportive Greens`}</h3>
    <p>{`We use the supportive green to indicate a succesfull action or to show a postive
message.`}</p>
    <ColorGrid name="green" mdxType="ColorGrid" />
    <h3 {...{
      "id": "supportive-reds",
      "style": {
        "position": "relative"
      }
    }}>{`Supportive Reds`}</h3>
    <p>{`We use the supportive red to indicate when an actions has failed or to show a
negative message.`}</p>
    <ColorGrid name="red" mdxType="ColorGrid" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      